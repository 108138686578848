<template>
<div class="row">
  <div class="col-md-12">


    <div class="position-relative mt-4 mb-3">
      <div class="bg-white p-4" style="border-radius: 10px;">
        <div  style="width: 70px;" class="position-absolute top-0 start-50 translate-middle " v-if="infousuario.foto">
          <figure class="figure">
            <img  :src="archivospublicos+'/personas/'+infousuario.foto"  class="img-thumbnail figure-img img-fluid rounded" alt="...">
          </figure>
        </div>
        <div class="titulosg mt-3">{{infousuario.nombres}} {{infousuario.apellidos}}</div>
        <router-link to="/perfil" class="btn btn-sm btn-primary rounded-pill mt-2" >Ver mi perfil</router-link>
      </div>
    </div>

    <!-- Menu General -->
    <div class="list-group mb-2">
      <router-link to="/Escritorio" class="list-group-item list-group-item-action"> <img src="/imagenes/ico/home.svg" class="me-2" style="width: 20px;" > Inicio</router-link>
    </div>
    
    <!-- Menu Estudiantes  -->
    <div id="menuestudiantes" class="list-group mb-2" v-if="infousuario.personascategoria_idpersonascategoria==4">
        <router-link to="/misclases" class="list-group-item list-group-item-action"> <img src="/imagenes/ico/matriculas.svg" class="me-2" style="width: 20px;" > Clases</router-link>
    </div>


    <!-- salir -->
    <div class="list-group mt-2">            
      <router-link to="/salir" class="list-group-item list-group-item-action"> <img src="imagenes/ico/logout.svg" class="me-2" style="width: 20px;" > Salir</router-link>
    </div>

  </div>
  </div>
</template>

<script>

import { ref, onMounted } from 'vue'
import axios from "axios";

export default {
  setup(){
      
      const infousuario = ref([])

      const consultarinfousuario = () => {

          axios.get("/api/personalogueada").then(response => { 
            
            infousuario.value = response.data

            }).catch(function (error) { console.log("error" + error); });

      }

      onMounted(() => {
          consultarinfousuario();
      })

      return {  infousuario } 

    }
}
</script>

<style scoped>

.links{
  color: black;
  text-decoration: none;
  border-radius: 10px;
  
} 

.links:hover{
    background-color: rgb(204, 225, 243);
    color: rgb(12, 7, 78);
}

.links:active{
    background-color: aqua;
}

</style>
