<template>
 
 <nav class="navbar navbar-expand-lg navbar-dark shadow-sm  d-print-none alpha " style="background-color: rgb(35, 39, 80);" > 
<div class="container">
      <a class="navbar-brand ps-4" href="/Escritorio">BaIT</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item dropdown me-2" v-for="permiso in permisos.filter(permisos => permisos.permisos_idpermisos === null)" :key="permiso.idpermiso">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             <span v-html="permiso.icono" ></span>   {{permiso.nombrepermiso}} 
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li v-for="subper in subpermisos" :key="subper.idpermisos">
               <router-link v-if="permiso.idpermisos == subper.permisos_idpermisos" class="dropdown-item" :to="`/${subper.linkpermiso}`">
                       <span v-html="subper.icono" class="ms-2 me-2" ></span> {{subper.nombrepermiso}}
                </router-link>   
              </li>
          </ul>
        </li>
      </ul>     
    </div>
</div>
</nav>  
 

</template>

<script>
    import { ref, onMounted } from 'vue'
    import axios from "axios";


  export default {
    setup(){
      
      const permisos = ref([])
      const subpermisos = ref([])
      const infousuario = ref({})

      const token = localStorage.getItem('token');

      const listarPermisos = () => {

        axios.get("/api/permisosxusuario").then(response => {
            
            permisos.value = response.data;
            subpermisos.value = response.data;

          }).catch(function (error) {
            console.log("error" + error);
          });

      }

      const consultarinfousuario = () => {

          axios.get("/api/user").then(response => {
            
            infousuario.value = response.data;

            }).catch(function (error) {
              console.log("error" + error);
            });

      }

      onMounted(() => {

          listarPermisos();
          consultarinfousuario();

      })

      return { permisos, subpermisos, infousuario,  token } 

    }
 
  }
</script>