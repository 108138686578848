<template>
<div class="container-fluid p-0">

    <menuprincipal></menuprincipal>
      <div class="container">

        <div class="row mt-3">
          <div class="col-md-2 d-print-none pt-4 mb-3" >
            <menuvertical></menuvertical>
          </div>
          <div class="col-md-10 ps-4">
            <router-view class="pt-4"></router-view>
          </div>
        </div>
        
      </div>

</div>
</template>
<script>
  import menuprincipal from "../components/menuprincipal.vue";
  import menuvertical from "../components/Menuvertical.vue";

  export default {
    components: {
      menuprincipal,
      menuvertical
    },
    setup() {
  
  }
}
</script>